export const albums_data = {
   "activities": {
      "name": "Activities",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "20240224.jpg",
            "slug": "20240224-jpg",
            "src": "/static/_gallery/albums/activities/1600x2133_20240224-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities/1024x1365_20240224-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities/1600x2133_20240224-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities/500x666_20240224-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities/800x1066_20240224-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities/500x666_20240224-jpg.jpg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "20240317.jpg",
            "slug": "20240317-jpg",
            "src": "/static/_gallery/albums/activities/1600x2133_20240317-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities/1024x1365_20240317-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities/1600x2133_20240317-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities/500x666_20240317-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities/800x1066_20240317-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities/500x666_20240317-jpg.jpg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3456,
            "name": "20240330.jpg",
            "slug": "20240330-jpg",
            "src": "/static/_gallery/albums/activities/1600x1200_20240330-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities/1024x768_20240330-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities/1600x1200_20240330-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities/500x375_20240330-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities/800x600_20240330-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities/500x375_20240330-jpg.jpg",
            "width": 4608
         },
         {
            "faces": [],
            "height": 2400,
            "name": "20240404.jpg",
            "slug": "20240404-jpg",
            "src": "/static/_gallery/albums/activities/1080x2400_20240404-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities/1024x2275_20240404-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities/1080x2400_20240404-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities/499x1111_20240404-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities/800x1777_20240404-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities/499x1111_20240404-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240428.jpg",
            "slug": "20240428-jpg",
            "src": "/static/_gallery/albums/activities/1600x1200_20240428-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities/1024x768_20240428-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities/1600x1200_20240428-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities/500x375_20240428-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities/800x600_20240428-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities/500x375_20240428-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240501.jpg",
            "slug": "20240501-jpg",
            "src": "/static/_gallery/albums/activities/1600x1200_20240501-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities/1024x768_20240501-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities/1600x1200_20240501-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities/500x375_20240501-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities/800x600_20240501-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities/500x375_20240501-jpg.jpg",
            "width": 4000
         }
      ],
      "slug": "activities",
      "src": "/static/_gallery/albums/activities/500x666_20240224-jpg.jpg"
   },
   "activities-20240523": {
      "name": "activities > 20240523",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "c7d92dd59587536af08d6e60f489e9a.jpg",
            "slug": "c7d92dd59587536af08d6e60f489e9a-jpg",
            "src": "/static/_gallery/albums/activities-20240523/1600x2133_c7d92dd59587536af08d6e60f489e9a-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities-20240523/1024x1365_c7d92dd59587536af08d6e60f489e9a-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities-20240523/1600x2133_c7d92dd59587536af08d6e60f489e9a-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities-20240523/500x666_c7d92dd59587536af08d6e60f489e9a-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities-20240523/800x1066_c7d92dd59587536af08d6e60f489e9a-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities-20240523/500x666_c7d92dd59587536af08d6e60f489e9a-jpg.jpg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "d5658e0e716dab299302c4d81bf02a0.jpg",
            "slug": "d5658e0e716dab299302c4d81bf02a0-jpg",
            "src": "/static/_gallery/albums/activities-20240523/1600x1200_d5658e0e716dab299302c4d81bf02a0-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/activities-20240523/1024x768_d5658e0e716dab299302c4d81bf02a0-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/activities-20240523/1600x1200_d5658e0e716dab299302c4d81bf02a0-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/activities-20240523/500x375_d5658e0e716dab299302c4d81bf02a0-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/activities-20240523/800x600_d5658e0e716dab299302c4d81bf02a0-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/activities-20240523/500x375_d5658e0e716dab299302c4d81bf02a0-jpg.jpg",
            "width": 4000
         }
      ],
      "slug": "activities-20240523",
      "src": "/static/_gallery/albums/activities-20240523/500x666_c7d92dd59587536af08d6e60f489e9a-jpg.jpg"
   },
   "flowers": {
      "name": "Flowers",
      "photos": [
         {
            "faces": [],
            "height": 3024,
            "name": "20240224.jpg",
            "slug": "20240224-jpg",
            "src": "/static/_gallery/albums/flowers/1600x1200_20240224-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flowers/1024x768_20240224-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flowers/1600x1200_20240224-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flowers/500x375_20240224-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flowers/800x600_20240224-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flowers/500x375_20240224-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 1440,
            "name": "20240412.jpg",
            "slug": "20240412-jpg",
            "src": "/static/_gallery/albums/flowers/1440x1440_20240412-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flowers/1024x1024_20240412-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flowers/1440x1440_20240412-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flowers/500x500_20240412-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flowers/800x800_20240412-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flowers/500x500_20240412-jpg.jpg",
            "width": 1440
         },
         {
            "faces": [],
            "height": 3024,
            "name": "20240414.jpg",
            "slug": "20240414-jpg",
            "src": "/static/_gallery/albums/flowers/1600x1200_20240414-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flowers/1024x768_20240414-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flowers/1600x1200_20240414-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flowers/500x375_20240414-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flowers/800x600_20240414-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flowers/500x375_20240414-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 1920,
            "name": "20240416.jpg",
            "slug": "20240416-jpg",
            "src": "/static/_gallery/albums/flowers/1080x1920_20240416-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flowers/1024x1820_20240416-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flowers/1080x1920_20240416-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flowers/499x888_20240416-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flowers/800x1422_20240416-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flowers/499x888_20240416-jpg.jpg",
            "width": 1080
         },
         {
            "faces": [],
            "height": 4000,
            "name": "20240419.jpg",
            "slug": "20240419-jpg",
            "src": "/static/_gallery/albums/flowers/1600x2133_20240419-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flowers/1024x1365_20240419-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flowers/1600x2133_20240419-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flowers/500x666_20240419-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flowers/800x1066_20240419-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flowers/500x666_20240419-jpg.jpg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 1920,
            "name": "20240427.jpg",
            "slug": "20240427-jpg",
            "src": "/static/_gallery/albums/flowers/1440x1920_20240427-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/flowers/1024x1365_20240427-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/flowers/1440x1920_20240427-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/flowers/500x666_20240427-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/flowers/800x1066_20240427-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/flowers/500x666_20240427-jpg.jpg",
            "width": 1440
         }
      ],
      "slug": "flowers",
      "src": "/static/_gallery/albums/flowers/500x375_20240224-jpg.jpg"
   },
   "food": {
      "name": "Food",
      "photos": [
         {
            "faces": [],
            "height": 3000,
            "name": "20240416.jpg",
            "slug": "20240416-jpg",
            "src": "/static/_gallery/albums/food/1600x1200_20240416-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x768_20240416-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1200_20240416-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x375_20240416-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x600_20240416-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x375_20240416-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240421.jpg",
            "slug": "20240421-jpg",
            "src": "/static/_gallery/albums/food/1600x1536_20240421-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x983_20240421-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1536_20240421-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x480_20240421-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x768_20240421-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x480_20240421-jpg.jpg",
            "width": 3125
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240427.jpg",
            "slug": "20240427-jpg",
            "src": "/static/_gallery/albums/food/1600x1200_20240427-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x768_20240427-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1200_20240427-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x375_20240427-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x600_20240427-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x375_20240427-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 4000,
            "name": "20240429.jpg",
            "slug": "20240429-jpg",
            "src": "/static/_gallery/albums/food/1600x2133_20240429-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x1365_20240429-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x2133_20240429-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x666_20240429-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x1066_20240429-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x666_20240429-jpg.jpg",
            "width": 3000
         },
         {
            "faces": [],
            "height": 3529,
            "name": "20240501.jpg",
            "slug": "20240501-jpg",
            "src": "/static/_gallery/albums/food/1600x2579_20240501-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x1650_20240501-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x2579_20240501-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x806_20240501-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x1289_20240501-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x806_20240501-jpg.jpg",
            "width": 2189
         },
         {
            "faces": [],
            "height": 2740,
            "name": "20240503.jpg",
            "slug": "20240503-jpg",
            "src": "/static/_gallery/albums/food/1600x1220_20240503-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x781_20240503-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1220_20240503-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x381_20240503-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x610_20240503-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x381_20240503-jpg.jpg",
            "width": 3592
         },
         {
            "faces": [],
            "height": 3024,
            "name": "20240504-alcohol.jpg",
            "slug": "20240504-alcohol-jpg",
            "src": "/static/_gallery/albums/food/1600x1200_20240504-alcohol-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x768_20240504-alcohol-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1200_20240504-alcohol-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x375_20240504-alcohol-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x600_20240504-alcohol-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x375_20240504-alcohol-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3024,
            "name": "20240504-ice-cream.jpg",
            "slug": "20240504-ice-cream-jpg",
            "src": "/static/_gallery/albums/food/1600x1200_20240504-ice-cream-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x768_20240504-ice-cream-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1200_20240504-ice-cream-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x375_20240504-ice-cream-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x600_20240504-ice-cream-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x375_20240504-ice-cream-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240504-\u87f9\u9ec4\u9762.jpg",
            "slug": "20240504-xie-huang-mian-jpg",
            "src": "/static/_gallery/albums/food/1600x1200_20240504-xie-huang-mian-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x768_20240504-xie-huang-mian-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1200_20240504-xie-huang-mian-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x375_20240504-xie-huang-mian-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x600_20240504-xie-huang-mian-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x375_20240504-xie-huang-mian-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240512.jpg",
            "slug": "20240512-jpg",
            "src": "/static/_gallery/albums/food/1600x1200_20240512-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x768_20240512-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1200_20240512-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/500x375_20240512-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x600_20240512-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/500x375_20240512-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 2502,
            "name": "20240523.jpg",
            "slug": "20240523-jpg",
            "src": "/static/_gallery/albums/food/1600x1533_20240523-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food/1024x981_20240523-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food/1600x1533_20240523-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food/499x479_20240523-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food/800x766_20240523-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food/499x479_20240523-jpg.jpg",
            "width": 2611
         }
      ],
      "slug": "food",
      "src": "/static/_gallery/albums/food/500x375_20240416-jpg.jpg"
   },
   "food-20240520": {
      "name": "food > 20240520",
      "photos": [
         {
            "faces": [],
            "height": 2516,
            "name": "138269d8345a25179248be323a20166.jpg",
            "slug": "138269d8345a25179248be323a20166-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x1329_138269d8345a25179248be323a20166-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x851_138269d8345a25179248be323a20166-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x1329_138269d8345a25179248be323a20166-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x415_138269d8345a25179248be323a20166-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x664_138269d8345a25179248be323a20166-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x415_138269d8345a25179248be323a20166-jpg.jpg",
            "width": 3027
         },
         {
            "faces": [],
            "height": 2752,
            "name": "2748a44289a113b91cb0e6a94752b0d.jpg",
            "slug": "2748a44289a113b91cb0e6a94752b0d-jpg",
            "src": "/static/_gallery/albums/food-20240520/1599x1330_2748a44289a113b91cb0e6a94752b0d-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x851_2748a44289a113b91cb0e6a94752b0d-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1599x1330_2748a44289a113b91cb0e6a94752b0d-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x415_2748a44289a113b91cb0e6a94752b0d-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/799x665_2748a44289a113b91cb0e6a94752b0d-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x415_2748a44289a113b91cb0e6a94752b0d-jpg.jpg",
            "width": 3309
         },
         {
            "faces": [],
            "height": 2927,
            "name": "2978d1ccb694fe2758c1bacb5964d26.jpg",
            "slug": "2978d1ccb694fe2758c1bacb5964d26-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x1381_2978d1ccb694fe2758c1bacb5964d26-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x883_2978d1ccb694fe2758c1bacb5964d26-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x1381_2978d1ccb694fe2758c1bacb5964d26-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x431_2978d1ccb694fe2758c1bacb5964d26-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x690_2978d1ccb694fe2758c1bacb5964d26-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x431_2978d1ccb694fe2758c1bacb5964d26-jpg.jpg",
            "width": 3391
         },
         {
            "faces": [],
            "height": 3055,
            "name": "38eef5b0c268f59014aeebcfbd718c4.jpg",
            "slug": "38eef5b0c268f59014aeebcfbd718c4-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x1810_38eef5b0c268f59014aeebcfbd718c4-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x1158_38eef5b0c268f59014aeebcfbd718c4-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x1810_38eef5b0c268f59014aeebcfbd718c4-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/499x565_38eef5b0c268f59014aeebcfbd718c4-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x905_38eef5b0c268f59014aeebcfbd718c4-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/499x565_38eef5b0c268f59014aeebcfbd718c4-jpg.jpg",
            "width": 2700
         },
         {
            "faces": [],
            "height": 2508,
            "name": "3adf379a09b418d87083f6be07583ca.jpg",
            "slug": "3adf379a09b418d87083f6be07583ca-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x1226_3adf379a09b418d87083f6be07583ca-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x784_3adf379a09b418d87083f6be07583ca-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x1226_3adf379a09b418d87083f6be07583ca-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x383_3adf379a09b418d87083f6be07583ca-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x613_3adf379a09b418d87083f6be07583ca-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x383_3adf379a09b418d87083f6be07583ca-jpg.jpg",
            "width": 3272
         },
         {
            "faces": [],
            "height": 4096,
            "name": "4e678c8556a710fa572c5edc87abc0e.jpg",
            "slug": "4e678c8556a710fa572c5edc87abc0e-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x2272_4e678c8556a710fa572c5edc87abc0e-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x1454_4e678c8556a710fa572c5edc87abc0e-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x2272_4e678c8556a710fa572c5edc87abc0e-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x710_4e678c8556a710fa572c5edc87abc0e-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x1136_4e678c8556a710fa572c5edc87abc0e-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x710_4e678c8556a710fa572c5edc87abc0e-jpg.jpg",
            "width": 2884
         },
         {
            "faces": [],
            "height": 2540,
            "name": "7d82a2a5176841007264c60050cb821.jpg",
            "slug": "7d82a2a5176841007264c60050cb821-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x1348_7d82a2a5176841007264c60050cb821-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x863_7d82a2a5176841007264c60050cb821-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x1348_7d82a2a5176841007264c60050cb821-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x421_7d82a2a5176841007264c60050cb821-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x674_7d82a2a5176841007264c60050cb821-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x421_7d82a2a5176841007264c60050cb821-jpg.jpg",
            "width": 3013
         },
         {
            "faces": [],
            "height": 2543,
            "name": "cf5b8bc0a1b5d2e6a220e53d2f8bff2.jpg",
            "slug": "cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg",
            "src": "/static/_gallery/albums/food-20240520/1600x1399_cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/food-20240520/1024x895_cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/food-20240520/1600x1399_cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/food-20240520/500x437_cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/food-20240520/800x699_cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/food-20240520/500x437_cf5b8bc0a1b5d2e6a220e53d2f8bff2-jpg.jpg",
            "width": 2907
         }
      ],
      "slug": "food-20240520",
      "src": "/static/_gallery/albums/food-20240520/500x415_138269d8345a25179248be323a20166-jpg.jpg"
   },
   "us-20240420": {
      "name": "us > 20240420",
      "photos": [
         {
            "faces": [],
            "height": 2950,
            "name": "eggplant.jpg",
            "slug": "eggplant-jpg",
            "src": "/static/_gallery/albums/us-20240420/1600x1171_eggplant-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240420/1024x749_eggplant-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240420/1600x1171_eggplant-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240420/499x366_eggplant-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240420/800x585_eggplant-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240420/499x366_eggplant-jpg.jpg",
            "width": 4030
         },
         {
            "faces": [],
            "height": 3024,
            "name": "ring.jpg",
            "slug": "ring-jpg",
            "src": "/static/_gallery/albums/us-20240420/1600x1200_ring-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240420/1024x768_ring-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240420/1600x1200_ring-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240420/500x375_ring-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240420/800x600_ring-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240420/500x375_ring-jpg.jpg",
            "width": 4032
         }
      ],
      "slug": "us-20240420",
      "src": "/static/_gallery/albums/us-20240420/499x366_eggplant-jpg.jpg"
   },
   "us-20240428": {
      "name": "us > 20240428",
      "photos": [
         {
            "faces": [],
            "height": 4032,
            "name": "486388f529862d8d649a2b9f590aa9b.jpg",
            "slug": "486388f529862d8d649a2b9f590aa9b-jpg",
            "src": "/static/_gallery/albums/us-20240428/1600x2133_486388f529862d8d649a2b9f590aa9b-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240428/1024x1365_486388f529862d8d649a2b9f590aa9b-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240428/1600x2133_486388f529862d8d649a2b9f590aa9b-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240428/500x666_486388f529862d8d649a2b9f590aa9b-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240428/800x1066_486388f529862d8d649a2b9f590aa9b-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240428/500x666_486388f529862d8d649a2b9f590aa9b-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 4032,
            "name": "92d6becda7961fc297a35dd794902a8.jpg",
            "slug": "92d6becda7961fc297a35dd794902a8-jpg",
            "src": "/static/_gallery/albums/us-20240428/1600x2133_92d6becda7961fc297a35dd794902a8-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240428/1024x1365_92d6becda7961fc297a35dd794902a8-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240428/1600x2133_92d6becda7961fc297a35dd794902a8-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240428/500x666_92d6becda7961fc297a35dd794902a8-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240428/800x1066_92d6becda7961fc297a35dd794902a8-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240428/500x666_92d6becda7961fc297a35dd794902a8-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 2880,
            "name": "cd412bf5ddf6537313ff79a785eef2c.jpg",
            "slug": "cd412bf5ddf6537313ff79a785eef2c-jpg",
            "src": "/static/_gallery/albums/us-20240428/1600x2133_cd412bf5ddf6537313ff79a785eef2c-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240428/1024x1365_cd412bf5ddf6537313ff79a785eef2c-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240428/1600x2133_cd412bf5ddf6537313ff79a785eef2c-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240428/499x666_cd412bf5ddf6537313ff79a785eef2c-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240428/800x1066_cd412bf5ddf6537313ff79a785eef2c-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240428/499x666_cd412bf5ddf6537313ff79a785eef2c-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 2880,
            "name": "d471c4a1700a15c03a5a57fed0d15a4.jpg",
            "slug": "d471c4a1700a15c03a5a57fed0d15a4-jpg",
            "src": "/static/_gallery/albums/us-20240428/1600x2133_d471c4a1700a15c03a5a57fed0d15a4-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240428/1024x1365_d471c4a1700a15c03a5a57fed0d15a4-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240428/1600x2133_d471c4a1700a15c03a5a57fed0d15a4-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240428/499x666_d471c4a1700a15c03a5a57fed0d15a4-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240428/800x1066_d471c4a1700a15c03a5a57fed0d15a4-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240428/499x666_d471c4a1700a15c03a5a57fed0d15a4-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 4032,
            "name": "f1c97a2389f4c561466354fd3e8a4f4.jpg",
            "slug": "f1c97a2389f4c561466354fd3e8a4f4-jpg",
            "src": "/static/_gallery/albums/us-20240428/1600x2133_f1c97a2389f4c561466354fd3e8a4f4-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240428/1024x1365_f1c97a2389f4c561466354fd3e8a4f4-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240428/1600x2133_f1c97a2389f4c561466354fd3e8a4f4-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240428/500x666_f1c97a2389f4c561466354fd3e8a4f4-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240428/800x1066_f1c97a2389f4c561466354fd3e8a4f4-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240428/500x666_f1c97a2389f4c561466354fd3e8a4f4-jpg.jpg",
            "width": 3024
         },
         {
            "faces": [],
            "height": 2880,
            "name": "fe147fa6dd5cf174114c4041238ee98.jpg",
            "slug": "fe147fa6dd5cf174114c4041238ee98-jpg",
            "src": "/static/_gallery/albums/us-20240428/1600x2133_fe147fa6dd5cf174114c4041238ee98-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240428/1024x1365_fe147fa6dd5cf174114c4041238ee98-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240428/1600x2133_fe147fa6dd5cf174114c4041238ee98-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240428/499x666_fe147fa6dd5cf174114c4041238ee98-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240428/800x1066_fe147fa6dd5cf174114c4041238ee98-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240428/499x666_fe147fa6dd5cf174114c4041238ee98-jpg.jpg",
            "width": 2160
         }
      ],
      "slug": "us-20240428",
      "src": "/static/_gallery/albums/us-20240428/500x666_486388f529862d8d649a2b9f590aa9b-jpg.jpg"
   },
   "us-20240524": {
      "name": "us > 20240524",
      "photos": [
         {
            "faces": [],
            "height": 2880,
            "name": "c1222fa63cb16f5b7ae6119adbfeda6.jpg",
            "slug": "c1222fa63cb16f5b7ae6119adbfeda6-jpg",
            "src": "/static/_gallery/albums/us-20240524/1600x2133_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240524/1024x1365_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240524/1600x2133_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240524/499x666_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240524/800x1066_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240524/499x666_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 2880,
            "name": "f577c11960024fda828c060b1019709.jpg",
            "slug": "f577c11960024fda828c060b1019709-jpg",
            "src": "/static/_gallery/albums/us-20240524/1600x2133_f577c11960024fda828c060b1019709-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-20240524/1024x1365_f577c11960024fda828c060b1019709-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-20240524/1600x2133_f577c11960024fda828c060b1019709-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-20240524/499x666_f577c11960024fda828c060b1019709-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-20240524/800x1066_f577c11960024fda828c060b1019709-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-20240524/499x666_f577c11960024fda828c060b1019709-jpg.jpg",
            "width": 2160
         }
      ],
      "slug": "us-20240524",
      "src": "/static/_gallery/albums/us-20240524/499x666_c1222fa63cb16f5b7ae6119adbfeda6-jpg.jpg"
   },
   "us-hl": {
      "name": "us > hl",
      "photos": [
         {
            "faces": [],
            "height": 4000,
            "name": "7be59aee2a5034d829539bda4c62ba8.jpg",
            "slug": "7be59aee2a5034d829539bda4c62ba8-jpg",
            "src": "/static/_gallery/albums/us-hl/1600x2133_7be59aee2a5034d829539bda4c62ba8-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-hl/1024x1365_7be59aee2a5034d829539bda4c62ba8-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-hl/1600x2133_7be59aee2a5034d829539bda4c62ba8-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-hl/500x666_7be59aee2a5034d829539bda4c62ba8-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-hl/800x1066_7be59aee2a5034d829539bda4c62ba8-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-hl/500x666_7be59aee2a5034d829539bda4c62ba8-jpg.jpg",
            "width": 3000
         }
      ],
      "slug": "us-hl",
      "src": "/static/_gallery/albums/us-hl/500x666_7be59aee2a5034d829539bda4c62ba8-jpg.jpg"
   },
   "us-ll": {
      "name": "us > ll",
      "photos": [
         {
            "faces": [],
            "height": 2876,
            "name": "12bdbec035904bf22302a48e3310236.jpg",
            "slug": "12bdbec035904bf22302a48e3310236-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x2130_12bdbec035904bf22302a48e3310236-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1363_12bdbec035904bf22302a48e3310236-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x2130_12bdbec035904bf22302a48e3310236-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/499x665_12bdbec035904bf22302a48e3310236-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1065_12bdbec035904bf22302a48e3310236-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/499x665_12bdbec035904bf22302a48e3310236-jpg.jpg",
            "width": 2160
         },
         {
            "faces": [],
            "height": 1906,
            "name": "18bc959830ea3f23561cdbadaf64d74.jpg",
            "slug": "18bc959830ea3f23561cdbadaf64d74-jpg",
            "src": "/static/_gallery/albums/us-ll/1280x1906_18bc959830ea3f23561cdbadaf64d74-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1524_18bc959830ea3f23561cdbadaf64d74-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1280x1906_18bc959830ea3f23561cdbadaf64d74-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x744_18bc959830ea3f23561cdbadaf64d74-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1191_18bc959830ea3f23561cdbadaf64d74-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x744_18bc959830ea3f23561cdbadaf64d74-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 1906,
            "name": "18c381a5044743d77896e7b5fb110e8.jpg",
            "slug": "18c381a5044743d77896e7b5fb110e8-jpg",
            "src": "/static/_gallery/albums/us-ll/1280x1906_18c381a5044743d77896e7b5fb110e8-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1524_18c381a5044743d77896e7b5fb110e8-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1280x1906_18c381a5044743d77896e7b5fb110e8-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x744_18c381a5044743d77896e7b5fb110e8-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1191_18c381a5044743d77896e7b5fb110e8-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x744_18c381a5044743d77896e7b5fb110e8-jpg.jpg",
            "width": 1280
         },
         {
            "faces": [],
            "height": 1772,
            "name": "301feb9fa9f4cb3906cd48b0af1cae9.jpg",
            "slug": "301feb9fa9f4cb3906cd48b0af1cae9-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x738_301feb9fa9f4cb3906cd48b0af1cae9-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x472_301feb9fa9f4cb3906cd48b0af1cae9-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x738_301feb9fa9f4cb3906cd48b0af1cae9-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x230_301feb9fa9f4cb3906cd48b0af1cae9-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x369_301feb9fa9f4cb3906cd48b0af1cae9-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x230_301feb9fa9f4cb3906cd48b0af1cae9-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 600,
            "name": "3e7e6edabe542c718b3e952b5a1e821.jpg",
            "slug": "3e7e6edabe542c718b3e952b5a1e821-jpg",
            "src": "/static/_gallery/albums/us-ll/800x600_3e7e6edabe542c718b3e952b5a1e821-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/800x600_3e7e6edabe542c718b3e952b5a1e821-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/800x600_3e7e6edabe542c718b3e952b5a1e821-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x375_3e7e6edabe542c718b3e952b5a1e821-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x600_3e7e6edabe542c718b3e952b5a1e821-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x375_3e7e6edabe542c718b3e952b5a1e821-jpg.jpg",
            "width": 800
         },
         {
            "faces": [],
            "height": 1204,
            "name": "68d9088ce05c99b0e53e29e3152df18.jpg",
            "slug": "68d9088ce05c99b0e53e29e3152df18-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x738_68d9088ce05c99b0e53e29e3152df18-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x472_68d9088ce05c99b0e53e29e3152df18-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x738_68d9088ce05c99b0e53e29e3152df18-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x230_68d9088ce05c99b0e53e29e3152df18-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x369_68d9088ce05c99b0e53e29e3152df18-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x230_68d9088ce05c99b0e53e29e3152df18-jpg.jpg",
            "width": 2610
         },
         {
            "faces": [],
            "height": 840,
            "name": "6f998ddf57c154d64544de68aeef89d.jpg",
            "slug": "6f998ddf57c154d64544de68aeef89d-jpg",
            "src": "/static/_gallery/albums/us-ll/1425x840_6f998ddf57c154d64544de68aeef89d-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x603_6f998ddf57c154d64544de68aeef89d-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1425x840_6f998ddf57c154d64544de68aeef89d-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x294_6f998ddf57c154d64544de68aeef89d-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x471_6f998ddf57c154d64544de68aeef89d-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x294_6f998ddf57c154d64544de68aeef89d-jpg.jpg",
            "width": 1425
         },
         {
            "faces": [],
            "height": 450,
            "name": "888397ab328bb8b3eed01468495c4a0.jpg",
            "slug": "888397ab328bb8b3eed01468495c4a0-jpg",
            "src": "/static/_gallery/albums/us-ll/800x450_888397ab328bb8b3eed01468495c4a0-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/800x450_888397ab328bb8b3eed01468495c4a0-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/800x450_888397ab328bb8b3eed01468495c4a0-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x281_888397ab328bb8b3eed01468495c4a0-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x450_888397ab328bb8b3eed01468495c4a0-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x281_888397ab328bb8b3eed01468495c4a0-jpg.jpg",
            "width": 800
         },
         {
            "faces": [],
            "height": 960,
            "name": "9951d7a9ca371e05d67c4112df502a5.jpg",
            "slug": "9951d7a9ca371e05d67c4112df502a5-jpg",
            "src": "/static/_gallery/albums/us-ll/960x960_9951d7a9ca371e05d67c4112df502a5-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/960x960_9951d7a9ca371e05d67c4112df502a5-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/960x960_9951d7a9ca371e05d67c4112df502a5-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x500_9951d7a9ca371e05d67c4112df502a5-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x800_9951d7a9ca371e05d67c4112df502a5-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x500_9951d7a9ca371e05d67c4112df502a5-jpg.jpg",
            "width": 960
         },
         {
            "faces": [],
            "height": 450,
            "name": "a13950f9be980a4d8d9300ca65a5f18.jpg",
            "slug": "a13950f9be980a4d8d9300ca65a5f18-jpg",
            "src": "/static/_gallery/albums/us-ll/800x450_a13950f9be980a4d8d9300ca65a5f18-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/800x450_a13950f9be980a4d8d9300ca65a5f18-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/800x450_a13950f9be980a4d8d9300ca65a5f18-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x281_a13950f9be980a4d8d9300ca65a5f18-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x450_a13950f9be980a4d8d9300ca65a5f18-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x281_a13950f9be980a4d8d9300ca65a5f18-jpg.jpg",
            "width": 800
         },
         {
            "faces": [],
            "height": 1080,
            "name": "b2a41517df7188eab73ee97404050e0.jpg",
            "slug": "b2a41517df7188eab73ee97404050e0-jpg",
            "src": "/static/_gallery/albums/us-ll/1440x1080_b2a41517df7188eab73ee97404050e0-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x768_b2a41517df7188eab73ee97404050e0-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1440x1080_b2a41517df7188eab73ee97404050e0-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x375_b2a41517df7188eab73ee97404050e0-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x600_b2a41517df7188eab73ee97404050e0-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x375_b2a41517df7188eab73ee97404050e0-jpg.jpg",
            "width": 1440
         },
         {
            "faces": [],
            "height": 2245,
            "name": "c33e76c7066e31fa4b7981f0e1695d0.jpg",
            "slug": "c33e76c7066e31fa4b7981f0e1695d0-jpg",
            "src": "/static/_gallery/albums/us-ll/1290x2245_c33e76c7066e31fa4b7981f0e1695d0-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1782_c33e76c7066e31fa4b7981f0e1695d0-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1290x2245_c33e76c7066e31fa4b7981f0e1695d0-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x870_c33e76c7066e31fa4b7981f0e1695d0-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1392_c33e76c7066e31fa4b7981f0e1695d0-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x870_c33e76c7066e31fa4b7981f0e1695d0-jpg.jpg",
            "width": 1290
         },
         {
            "faces": [],
            "height": 2320,
            "name": "c430572d7640cfaa6eca845f0f9d47e.jpg",
            "slug": "c430572d7640cfaa6eca845f0f9d47e-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x1202_c430572d7640cfaa6eca845f0f9d47e-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x769_c430572d7640cfaa6eca845f0f9d47e-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x1202_c430572d7640cfaa6eca845f0f9d47e-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x375_c430572d7640cfaa6eca845f0f9d47e-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x601_c430572d7640cfaa6eca845f0f9d47e-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x375_c430572d7640cfaa6eca845f0f9d47e-jpg.jpg",
            "width": 3088
         },
         {
            "faces": [],
            "height": 1080,
            "name": "c7b97fca10291b9755d5f7c4ceeb9a8.jpg",
            "slug": "c7b97fca10291b9755d5f7c4ceeb9a8-jpg",
            "src": "/static/_gallery/albums/us-ll/1437x1080_c7b97fca10291b9755d5f7c4ceeb9a8-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x769_c7b97fca10291b9755d5f7c4ceeb9a8-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1437x1080_c7b97fca10291b9755d5f7c4ceeb9a8-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x375_c7b97fca10291b9755d5f7c4ceeb9a8-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x601_c7b97fca10291b9755d5f7c4ceeb9a8-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x375_c7b97fca10291b9755d5f7c4ceeb9a8-jpg.jpg",
            "width": 1437
         },
         {
            "faces": [],
            "height": 2235,
            "name": "cb7ea6004fa737d874bdd565cc07fd9.jpg",
            "slug": "cb7ea6004fa737d874bdd565cc07fd9-jpg",
            "src": "/static/_gallery/albums/us-ll/1290x2235_cb7ea6004fa737d874bdd565cc07fd9-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1774_cb7ea6004fa737d874bdd565cc07fd9-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1290x2235_cb7ea6004fa737d874bdd565cc07fd9-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x866_cb7ea6004fa737d874bdd565cc07fd9-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1386_cb7ea6004fa737d874bdd565cc07fd9-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x866_cb7ea6004fa737d874bdd565cc07fd9-jpg.jpg",
            "width": 1290
         },
         {
            "faces": [],
            "height": 2426,
            "name": "d145840aae3049827af2fec10d985b6.jpg",
            "slug": "d145840aae3049827af2fec10d985b6-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x1024_d145840aae3049827af2fec10d985b6-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x655_d145840aae3049827af2fec10d985b6-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x1024_d145840aae3049827af2fec10d985b6-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x320_d145840aae3049827af2fec10d985b6-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x512_d145840aae3049827af2fec10d985b6-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x320_d145840aae3049827af2fec10d985b6-jpg.jpg",
            "width": 3789
         },
         {
            "faces": [],
            "height": 1437,
            "name": "d965558a4bfde22bd64daf2a45784a9.jpg",
            "slug": "d965558a4bfde22bd64daf2a45784a9-jpg",
            "src": "/static/_gallery/albums/us-ll/1079x1437_d965558a4bfde22bd64daf2a45784a9-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1363_d965558a4bfde22bd64daf2a45784a9-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1079x1437_d965558a4bfde22bd64daf2a45784a9-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x665_d965558a4bfde22bd64daf2a45784a9-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1065_d965558a4bfde22bd64daf2a45784a9-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x665_d965558a4bfde22bd64daf2a45784a9-jpg.jpg",
            "width": 1079
         },
         {
            "faces": [],
            "height": 3024,
            "name": "def966f2431844cb6407807b9697a15.jpg",
            "slug": "def966f2431844cb6407807b9697a15-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x1200_def966f2431844cb6407807b9697a15-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x768_def966f2431844cb6407807b9697a15-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x1200_def966f2431844cb6407807b9697a15-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x375_def966f2431844cb6407807b9697a15-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x600_def966f2431844cb6407807b9697a15-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x375_def966f2431844cb6407807b9697a15-jpg.jpg",
            "width": 4032
         },
         {
            "faces": [],
            "height": 2160,
            "name": "eb7277e9593204a66d5d380557d279f.jpg",
            "slug": "eb7277e9593204a66d5d380557d279f-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x900_eb7277e9593204a66d5d380557d279f-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x576_eb7277e9593204a66d5d380557d279f-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x900_eb7277e9593204a66d5d380557d279f-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x281_eb7277e9593204a66d5d380557d279f-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x450_eb7277e9593204a66d5d380557d279f-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x281_eb7277e9593204a66d5d380557d279f-jpg.jpg",
            "width": 3840
         },
         {
            "faces": [],
            "height": 2160,
            "name": "f312da03ecb48311e6eecfd6f661e5e.jpg",
            "slug": "f312da03ecb48311e6eecfd6f661e5e-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x1200_f312da03ecb48311e6eecfd6f661e5e-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x768_f312da03ecb48311e6eecfd6f661e5e-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x1200_f312da03ecb48311e6eecfd6f661e5e-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/500x375_f312da03ecb48311e6eecfd6f661e5e-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x600_f312da03ecb48311e6eecfd6f661e5e-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/500x375_f312da03ecb48311e6eecfd6f661e5e-jpg.jpg",
            "width": 2880
         },
         {
            "faces": [],
            "height": 2880,
            "name": "fdd22a8994a4eb315aa1352221e8dd5.jpg",
            "slug": "fdd22a8994a4eb315aa1352221e8dd5-jpg",
            "src": "/static/_gallery/albums/us-ll/1600x2133_fdd22a8994a4eb315aa1352221e8dd5-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/us-ll/1024x1365_fdd22a8994a4eb315aa1352221e8dd5-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/us-ll/1600x2133_fdd22a8994a4eb315aa1352221e8dd5-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/us-ll/499x666_fdd22a8994a4eb315aa1352221e8dd5-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/us-ll/800x1066_fdd22a8994a4eb315aa1352221e8dd5-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/us-ll/499x666_fdd22a8994a4eb315aa1352221e8dd5-jpg.jpg",
            "width": 2160
         }
      ],
      "slug": "us-ll",
      "src": "/static/_gallery/albums/us-ll/499x665_12bdbec035904bf22302a48e3310236-jpg.jpg"
   }
};